import React, { useState, useEffect, createContext } from 'react';
import { encode } from 'js-base64';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import mixpanel from 'mixpanel-browser';
import api, {
  setAuthenticationHeaders,
  setInterceptors,
  unsetAuthenticationHeaders,
  unsetInterceptors,
} from '~/lib/api';
import { TOKEN_KEY } from '~/lib/constants';
import useNotification from '~/hooks/use-notification';
import LoginActionsContainer from '~/containers/LoginActions';
import { MultiFactorAuthentication, ChooseOrganization, UserIsLocked } from '~/components/LoginActionElements';
import { fromApi as featureFlagsFromApi } from '~/lib/data-parsers/feature-flags';
import { organizationEventsFromApi, userEventsFromApi } from '~/lib/data-parsers/event-flags';

const fromApi = organization => ({
  id: organization.id,
  name: organization.name,
  featureFlags: featureFlagsFromApi(organization.feature_flags),
  eventFlags: organizationEventsFromApi(organization.event_flags),
});

const AuthenticationContext = createContext();

function AuthenticationProvider(props) {
  const history = useHistory();
  const cachedOrganizationId = window.localStorage.getItem('onomondo-organization-id') || null;

  const { addNotification, cancelAllNotifications } = useNotification();
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [userType, setUserType] = useState(null);
  const [userId, setUserId] = useState(null);
  const [accessLevel, setAccessLevel] = useState(null);
  const [writeTags, setWriteTags] = useState(true);
  const [readTags, setReadTags] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [events, setEvents] = useState([]);
  const [assumedOrganizationId, setAssumedOrganizationId] = useState(
    cachedOrganizationId && Number(cachedOrganizationId),
  );
  const [isViewingAsAssumedOrganization, setIsViewingAsAssumedOrganization] = useState(false);
  const [action, setAction] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [organizations, setOrganizations] = useState(null);
  const [usersOrganizations, setUsersOrganizations] = useState([]);
  const [usersCurrentOrganizationId, setUsersCurrentOrganizationId] = useState(null);
  const [authorizationTokenForLaterUse, setAuthorizationTokenForLaterUse] = useState(null);

  async function getMe() {
    try {
      const { data } = await api.get('/me');
      const events = userEventsFromApi(data.events);
      const accessLevel = data.type;
      const userType = data.user_type;
      setWriteTags(data.tags.filter(t => t.can_write));
      setReadTags(data.tags.filter(t => !t.can_write));
      setEmail(data.email);
      setIsMfaEnabled(data.is_mfa_enabled);
      setUserType(userType);
      setAccessLevel(accessLevel);
      setUsersOrganizations(data.organizations.map(fromApi));
      setUsersCurrentOrganizationId(data.current_organization_id);
      setIsLoggedIn(true);
      setIsLoggingIn(false);
      setUserId(data.id);
      setEvents(events);

      mixpanel.identify(data.id);

      const currentOrganizationName = data.organizations.find(o => o.id === data.current_organization_id).name;
      const currentOrganizationId = data.organizations.find(o => o.id === data.current_organization_id).id;

      window.Appcues?.identify(data.id, {
        // recommended (optional) properties
        // createdAt: 1566932390, // Unix timestamp of user signup date
        // purchasedAd: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
        // planTier: "Standard", // Current user’s plan tier
        userType: data.type, // Current user’s role or permissions
        // accountId: "1234", // Current user's account ID
        // firstName: "John", // current user's first name

        // additional suggestions
        organizationName: currentOrganizationName, // Current user’s company name
        organizationId: currentOrganizationId, // Current user’s company UUID
        // email: data.email, // Current user's email
        // location: "90210", // a zipcode, state, or country enables location-based targeting
        // version: "2.0", // users on different versions may need to see different content
        // language: "spanish", // for multi-language applications
        // renewalDate: 1577880288 // to remind users to renew
      });

      const isIntercomDefined = typeof window.Intercom === 'function';
      if (!isIntercomDefined) return;

      window.Intercom('boot', {
        api_base: 'https://api-iam.eu.intercom.io',
        app_id: process.env.INTERCOM_APP_ID,
        name: null,
        user_id: data.id,
        user_hash: data.user_hash,
        email: data.email,
        company: {
          name: currentOrganizationName,
          id: currentOrganizationId,
        },
        companies: data.organizations,
      });
    } catch (e) {
      logout(e);
    }
  }

  useEffect(() => {
    if (!token) return;
    getMe();
  }, [token]);

  useEffect(() => {
    const shouldRemoveCache = assumedOrganizationId === null;
    setIsViewingAsAssumedOrganization(false);
    if (shouldRemoveCache) {
      window.localStorage.removeItem('onomondo-organization-id');
    } else {
      window.localStorage.setItem('onomondo-organization-id', assumedOrganizationId);
    }
  }, [assumedOrganizationId]);

  useEffect(() => {
    const token = window.localStorage.getItem(TOKEN_KEY);
    const hasTokenInStorage = !!token;

    if (!hasTokenInStorage) {
      setIsLoggingIn(false);
      return;
    }

    setAuthenticationHeaders(token);
    setInterceptors(history);
    setToken(token);
  }, []);

  async function triggerChooseOrganization({ chooseOrganizationToken }) {
    setAuthorizationTokenForLaterUse(chooseOrganizationToken);
    await getOrganizations({ chooseOrganizationToken });
  }

  const login = async ({ email, password }) => {
    if (!email || !password) {
      addNotification({
        message: 'Email and password are required',
        type: 'error',
      });
    }
    setIsLoggingIn(true);
    await authenticate({ email, password, path: '/auth' });
  };

  async function loginWithGoogle({ credential: jwtPotentiallyFromGoogle }) {
    if (!jwtPotentiallyFromGoogle) {
      addNotification({
        message: 'Access token was not provided',
        type: 'error',
      });
      return;
    }
    setIsLoggingIn(true);
    await authenticate({ token: jwtPotentiallyFromGoogle, path: '/auth/google' });
  }

  async function verifyMfaCode({ mfaCode }) {
    const {
      data: { token, action },
    } = await api.post(
      '/auth/mfa',
      { mfa_code: mfaCode },
      { headers: { authorization: `Bearer ${authorizationTokenForLaterUse}` } },
    );

    setAction(action);

    const shouldChooseOrganization = action === 'choose-organization';
    if (shouldChooseOrganization) await triggerChooseOrganization({ chooseOrganizationToken: token });
  }

  async function authenticate({ email, password, token, path }) {
    const isBasicAuthentication = path === '/auth';
    const headers = {
      authorization: isBasicAuthentication
        ? `Basic ${encode(`${email.toLowerCase()}:${password}`)}`
        : `Bearer ${token}`,
    };
    try {
      const {
        data: { token: tokenFromAuthentication, action },
      } = await api.get(path, { headers });

      setAction(action);
      setAuthorizationTokenForLaterUse(tokenFromAuthentication);

      const shouldChooseOrganization = action === 'choose-organization';
      if (!shouldChooseOrganization) return;

      await triggerChooseOrganization({ chooseOrganizationToken: tokenFromAuthentication });
    } catch (error) {
      const isAccountLocked = error?.response?.data?.error === 'user is locked';
      if (isAccountLocked) {
        setAction('user-is-locked');
        return;
      }
      logout(error);
    }
  }

  async function setUserCompletedSimPageOnboarding() {
    try {
      await api.post('/events/sim-page-onboarding-flow-completed');
    } catch (e) {
      logout(e);
    }
  }

  async function getOrganizations({ chooseOrganizationToken }) {
    try {
      const { data } = await api.get('/me/organizations', {
        headers: {
          authorization: `Bearer ${chooseOrganizationToken}`,
        },
      });
      if (data.length === 1) {
        await getToken({ organizationId: data[0].id, chooseOrganizationToken });
      }
      setOrganizations(data.map(o => ({ id: o.id, name: o.entity })));
    } catch (e) {
      logout(e);
    }
  }

  async function getToken({ organizationId, chooseOrganizationToken }) {
    try {
      const { data } = await api.get('/token', {
        headers: {
          authorization: `Bearer ${chooseOrganizationToken}`,
          organization_id: organizationId,
        },
      });
      mixpanel.set_group('organization_id', organizationId);
      const { token } = data;
      setAuthorizationTokenForLaterUse(null);
      setOrganizations(null);
      window.localStorage.setItem(TOKEN_KEY, token);
      setAuthenticationHeaders(token);
      setToken(token);
      cancelAllNotifications();
    } catch (e) {
      logout(e);
    }
  }

  async function changeOrganization(organizationId) {
    try {
      const { data } = await api.get(`/me/change-organization/${organizationId}`, {
        headers: {
          authorization: token,
        },
      });
      const { token: newToken } = data;
      window.localStorage.setItem(TOKEN_KEY, newToken);
      setAuthenticationHeaders(newToken);
      setToken(newToken);
      history.replace('/');
    } catch (e) {
      logout(e);
    }
  }

  const logout = e => {
    mixpanel.track('Log out');
    // If we don't have a user_id and reset, then we will lose the user data we have recorded so far, and can not connect it to a user when they log in.
    if (mixpanel.get_property('$user_id')) mixpanel.reset();

    setIsLoggingIn(false);
    window.localStorage.removeItem(TOKEN_KEY);
    const isIntercomDefined = typeof window.Intercom === 'function';
    if (isIntercomDefined) window.Intercom('shutdown');
    unsetAuthenticationHeaders();
    unsetInterceptors();
    setAssumedOrganizationId(null);
    setToken(null);
    setIsLoggedIn(false);
    let message = 'You have been logged out';
    if (e) {
      message = 'You have been logged out due to an error, the team has been notified';
      Sentry.captureException(e);
    }
    addNotification({ message, type: e ? 'error' : null });
  };

  const assumeOrganizationId = id => {
    setAssumedOrganizationId(id || null);
  };

  async function handleMfaSubmit(e) {
    e.preventDefault();
    try {
      await verifyMfaCode({ mfaCode: e.target.value });
    } catch (error) {
      const isAccountLocked = error?.response?.data?.error === 'user is locked';
      if (isAccountLocked) {
        setAction('user-is-locked');
        return;
      }
      const isIncorrectAuthenticationCode = error?.response?.status === 403;
      addNotification({
        message: isIncorrectAuthenticationCode
          ? 'Invalid authentication code or token'
          : error.message || 'Unkown, please contact support.',
        type: 'error',
      });
    }
  }

  const canWriteNetworkList = networkList => {
    if (accessLevel !== 'member') return true;
    const flatWriteTagsIds = writeTags.map(wt => wt.id);
    const flatNetworkListTagsIds = networkList.tags.map(nt => nt.id);
    const canWrite = flatWriteTagsIds.some(id => flatNetworkListTagsIds.includes(id));
    return canWrite;
  };

  function toggleIsViewingAsAssumedOrganization() {
    setIsViewingAsAssumedOrganization(!isViewingAsAssumedOrganization);
  }

  if (isLoggingIn) {
    return (
      <LoginActionsContainer>
        {action === 'choose-organization' && organizations?.length > 1 && (
          <ChooseOrganization
            organizations={organizations}
            onClick={organizationId =>
              getToken({ organizationId, chooseOrganizationToken: authorizationTokenForLaterUse })
            }
          />
        )}
        {action === 'multi-factor-authentication' && <MultiFactorAuthentication onSubmit={handleMfaSubmit} />}
        {action === 'user-is-locked' && <UserIsLocked />}
      </LoginActionsContainer>
    );
  }
  return (
    <AuthenticationContext.Provider
      value={{
        logout,
        login,
        loginWithGoogle,
        isLoggedIn,
        token,
        isGod: userType === 'god',
        isDemiGod: userType === 'demigod',
        isOwner: accessLevel === 'owner',
        isAdmin: accessLevel === 'admin',
        isOrgMember: accessLevel === 'member',
        accessLevel,
        userId,
        userType,
        email,
        events,
        isMfaEnabled,
        setIsMfaEnabled,
        writeTags,
        readTags,
        canWrite: accessLevel === 'member' ? writeTags.length > 0 : true,
        canView: accessLevel === 'member' ? readTags.length > 0 || writeTags.length > 0 : true,
        isViewingAsAssumedOrganization,
        toggleIsViewingAsAssumedOrganization,
        assumedOrganizationId,
        assumeOrganizationId,
        usersOrganizations,
        usersCurrentOrganizationId,
        changeOrganization,
        canWriteNetworkList,
        setUserCompletedSimPageOnboarding,
        getMe,
      }}
      {...props}
    />
  );
}

export { AuthenticationProvider, AuthenticationContext };
