import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '~/components/Button';
import PropTypes from 'prop-types';
import ModalForm from '~/components/ModalForm';
import Input from '~/components/Input';
import { useModali } from 'modali';

const StyledRow = styled.div`
  border-top: 1px solid ${props => props.theme.light};
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InfoColumn = styled.div`
  width: 275px;
  height: 30px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsColumn = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 20 0px;
  width: 190px;
  justify-content: space-between;
`;

const StyledSecretName = styled.h4`
  color: ${props => props.theme.darkMedium};
`;

function HiddenInput({ secretLabel, onChange, setShowConfirmBox, connectorType, secretKey }) {
  const [localValue, setLocalValue] = useState('');
  const [updateSecretModal, toggleUpdateSecretModal] = useModali({
    animated: true,
    title: `Update ${secretLabel}`,
  });

  const isTLS = connectorType === 'tls';
  const isDpsKeyPass = connectorType === 'microsoft_azure_dps' && secretKey === 'keyPass';

  function showSecretModal(e) {
    e.preventDefault();
    toggleUpdateSecretModal();
  }

  function onModalSubmit() {
    onChange(localValue, secretLabel);
    toggleUpdateSecretModal();
    setShowConfirmBox(true);
  }

  function deleteSecretValue() {
    setLocalValue('');
    onChange(localValue, secretLabel);
    setShowConfirmBox(true);
  }

  return (
    <>
      <StyledRow>
        <InfoColumn>
          <StyledSecretName>{secretLabel}</StyledSecretName>
        </InfoColumn>
        <ButtonsColumn>
          <Button onClick={showSecretModal}>Edit</Button>
          {(isTLS || isDpsKeyPass) && (
            <Button
              variant='danger'
              onClick={deleteSecretValue}
            >
              Delete
            </Button>
          )}
        </ButtonsColumn>
      </StyledRow>

      <ModalForm
        modalOpts={updateSecretModal}
        onClickSubmit={onModalSubmit}
        submitText='Update'
        onClickCancel={toggleUpdateSecretModal}
        submitDisabled={localValue === ''}
      >
        <Input
          type='textarea'
          label={`Update ${secretLabel}`}
          placeholder='Input text'
          onChange={e => setLocalValue(e.target.value)}
        />
      </ModalForm>
    </>
  );
}
export default HiddenInput;

HiddenInput.propTypes = {
  secretLabel: PropTypes.string,
  onChange: PropTypes.func,
  setShowConfirmBox: PropTypes.func,
  connectorType: PropTypes.string,
  secretKey: PropTypes.string,
};
