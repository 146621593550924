import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, HeaderImg } from '~/components/LoginElements';

function LoginActions({ children }) {
  return (
    <Wrapper>
      <HeaderImg
        width={145}
        height={20}
        src={require('~/assets/graphics/onomondo-logo-type-negative.svg')}
      />
      {children}
    </Wrapper>
  );
}

LoginActions.propTypes = {
  children: PropTypes.node,
};

export default LoginActions;
