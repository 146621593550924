import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledForm,
  NameInputGroup,
  ContinueBtn,
  StyledInput,
  FlexedInput,
  SubTitleWrapper,
  StyledContinueBtnWrapper,
} from './components/Elements';
import { SignUpCardTitle, SignUpCardSubtitle } from '~/components/Text';

function FillUserInfo({ formData, setFormData, unsafeMetadata, setUnsafeMetadata, errors, setErrors, nextStep }) {
  return (
    <>
      <SignUpCardTitle>Company information</SignUpCardTitle>
      <SubTitleWrapper>
        <SignUpCardSubtitle>Please provide some details about your company.</SignUpCardSubtitle>
      </SubTitleWrapper>
      <StyledForm>
        <StyledInput
          noMargin
          name='company-name'
          type='input'
          label='Company name'
          placeholder='Enter company name'
          onChange={e => {
            setUnsafeMetadata(prev => ({ ...prev, companyName: e.target.value }));
            setErrors(prev => ({ ...prev, companyName: null }));
          }}
          value={unsafeMetadata.companyName}
          error={!!errors.companyName}
          helpText={errors.companyName ? errors.companyName : ''}
        />
        <StyledInput
          noMargin
          name='use-case'
          type='textarea'
          label='Use case'
          placeholder='Describe your use case'
          optional
          onChange={e => {
            setUnsafeMetadata(prev => ({ ...prev, useCase: e.target.value }));
            setErrors(prev => ({ ...prev, useCase: null }));
          }}
          value={unsafeMetadata.useCase}
          error={!!errors.useCase}
          helpText={errors.useCase || ''}
        />
        <NameInputGroup>
          <FlexedInput
            noMargin
            name='first-name'
            type='input'
            label='First name'
            placeholder='Enter first name'
            onChange={e => {
              setFormData(prev => ({ ...prev, firstName: e.target.value }));
              setErrors(prev => ({ ...prev, firstName: null }));
            }}
            value={formData.firstName}
            error={!!errors.firstName}
            helpText={errors.firstName}
          />
          <FlexedInput
            noMargin
            name='last-name'
            type='input'
            label='Surname'
            placeholder='Enter surname'
            onChange={e => {
              setFormData(prev => ({ ...prev, lastName: e.target.value }));
              setErrors(prev => ({ ...prev, lastName: null }));
            }}
            value={formData.lastName}
            error={!!errors.lastName}
            helpText={errors.lastName ? errors.lastName : ''}
          />
        </NameInputGroup>
        <StyledInput
          noMargin
          name='job-title'
          type='input'
          label='Job Title'
          placeholder='Enter Job Title'
          onChange={e => {
            setUnsafeMetadata(prev => ({ ...prev, jobTitle: e.target.value }));
            setErrors(prev => ({ ...prev, jobTitle: null }));
          }}
          value={unsafeMetadata.jobTitle}
          error={!!errors.jobTitle}
          helpText={errors.jobTitle || ''}
        />
        <StyledInput
          noMargin
          type='input'
          label='How did you hear about us?'
          placeholder='Webinars, social media, events, referrals...'
          onChange={e => {
            setUnsafeMetadata({ ...unsafeMetadata, heardAboutOnomondoFrom: e.target.value });
            setErrors({ ...errors, heardAboutOnomondoFrom: '' });
          }}
          value={unsafeMetadata.heardAboutOnomondoFrom}
          error={!!errors.heardAboutOnomondoFrom}
          helpText={errors.heardAboutOnomondoFrom ? errors.heardAboutOnomondoFrom : ''}
        />
        <StyledContinueBtnWrapper>
          <ContinueBtn onClick={nextStep}>Continue</ContinueBtn>
        </StyledContinueBtnWrapper>
      </StyledForm>
    </>
  );
}

FillUserInfo.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  unsafeMetadata: PropTypes.object,
  setUnsafeMetadata: PropTypes.func,
  nextStep: PropTypes.func,
};

export default FillUserInfo;
