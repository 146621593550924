import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ContinueBtn,
  StyledInput,
  StyledForm,
  StyledLabelContainer,
  SubTitleWrapper,
  StyledContinueBtnWrapper,
  InfoMessage,
  ErrorMessage,
  WarningMessage,
  ErrorTypography,
  MessageTop,
  MessageButton,
  PromotionMessage,
  ModalParagraph,
} from './components/Elements';
import { SignUpCardTitle, Typography } from '~/components/Text';
import useLocationAndTechnologies from '~/hooks/use-location-and-technologies';
import Icon from '~/components/Icon';
import Link from '~/components/Link';
import theme from '~/lib/theme';
import { interleave } from '~/lib/array';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';

const LOADING_COUNTRIES = [{ label: 'Loading...', value: 'loading' }];
const GET_IN_TOUCH_LINK_PROPS = {
  url: process.env.GET_IN_TOUCH_CTA_URL,
  method: 'external',
};

const TECH_DISPLAY_NAMES = {
  lteM: 'LTE-M',
  nbIoT: 'NB-IoT',
  '4G': 'LTE',
};

const CountryInput = styled(StyledInput)`
  ${props => !props.value && 'margin-bottom: 40px;'}
`;

function NoTechnologies({ techExclusivelyInPoc }) {
  const text = techExclusivelyInPoc.length
    ? 'No available free trial networks in this country.'
    : 'No available networks in this country.';

  return (
    <ErrorMessage>
      <MessageTop>
        <Icon
          height='20'
          width='20'
          inline
          name='alert-2'
          color={theme.color.primary.red[600]}
        />
        <ErrorTypography>{text}</ErrorTypography>
      </MessageTop>
    </ErrorMessage>
  );
}

NoTechnologies.propTypes = {
  techExclusivelyInPoc: PropTypes.arrayOf(PropTypes.shape()),
};

function ShippingNotAvailable({ location }) {
  return (
    <ErrorMessage>
      <MessageTop>
        <Icon
          height='20'
          width='20'
          inline
          name='package-x'
          color={theme.color.primary.red[600]}
        />
        <ErrorTypography>Shipping to this country is unavailable.</ErrorTypography>
      </MessageTop>
      {!location.shippingData.importPossible && (
        <MessageButton linkProps={GET_IN_TOUCH_LINK_PROPS}>Get in touch</MessageButton>
      )}
    </ErrorMessage>
  );
}

ShippingNotAvailable.propTypes = {
  location: PropTypes.shape(),
};

function PocPromotion({ techExclusivelyInPoc }) {
  const technologiesStringArray = techExclusivelyInPoc.map(t => {
    return TECH_DISPLAY_NAMES[t.tech] || t.tech;
  });

  const technologiesString = technologiesStringArray.join(', ');
  const techWord = techExclusivelyInPoc.length > 1 ? 'technologies' : 'technology';

  return (
    <PromotionMessage>
      <MessageTop>
        <Icon
          height='20'
          width='20'
          inline
          name='coins-hand'
          color={theme.color.temp.promotion.icon}
        />
        <div>
          <Typography color={theme.color.temp.promotion.typography}>
            Available paid trial network {techWord}:
          </Typography>
          <Typography color={theme.color.temp.promotion.typography}>{technologiesString}</Typography>
        </div>
      </MessageTop>
      <MessageButton linkProps={GET_IN_TOUCH_LINK_PROPS}>Get in touch</MessageButton>
    </PromotionMessage>
  );
}

PocPromotion.propTypes = {
  techExclusivelyInPoc: PropTypes.arrayOf(PropTypes.shape()),
};

function CustomsFeeRequired() {
  return (
    <WarningMessage>
      <MessageTop>
        <Icon
          height='20'
          width='20'
          inline
          name='coins-stacked-02'
          color={theme.color.temp.warning.icon}
        />
        <Typography color={theme.color.temp.warning.typography}>
          SIMs shipped to this country may incur a customs fee.
        </Typography>
      </MessageTop>
    </WarningMessage>
  );
}

function HasTechnologies({ freeTrialTechnologies }) {
  let hasAnyPermanentRoamingRestrictions = false;

  const technologiesRenderArray = freeTrialTechnologies.map(t => {
    const techDisplayName = TECH_DISPLAY_NAMES[t.tech] || t.tech;

    if (t.permanentRoamingRestriction) {
      hasAnyPermanentRoamingRestrictions = true;
      return <i key={t.tech}>{techDisplayName}*</i>;
    }
    return techDisplayName;
  });

  const technologiesStringArrayWithCommas = interleave(technologiesRenderArray, ', ');

  const techWord = freeTrialTechnologies.length > 1 ? 'technologies' : 'technology';

  return (
    <InfoMessage>
      <MessageTop>
        <Icon
          height='20'
          width='20'
          inline
          name='globe'
        />
        <div>
          <Typography color={theme.color.temp.info.typography.primary}>
            Available free trial network {techWord}:
          </Typography>
          <Typography color={theme.color.temp.info.typography.primary}>{technologiesStringArrayWithCommas}</Typography>
          {hasAnyPermanentRoamingRestrictions && (
            <Typography
              type='label'
              color={theme.color.temp.info.typography.secondary}
            >
              *No permanent roaming available
            </Typography>
          )}
        </div>
      </MessageTop>
    </InfoMessage>
  );
}

HasTechnologies.propTypes = {
  freeTrialTechnologies: PropTypes.arrayOf(PropTypes.shape()),
};

const MESSAGE_MAP = {
  noTechnologies: {
    Component: NoTechnologies,
    mixpanelValue: 'Self sign up no available technologies',
  },
  pocPromotion: {
    Component: PocPromotion,
    mixpanelValue: 'Self sign up poc promotion',
  },
  shippingNotAvailable: {
    Component: ShippingNotAvailable,
    mixpanelValue: 'Self sign up shipping not available',
  },
  shippingNotAvailableWithGetInTouch: {
    Component: ShippingNotAvailable,
    mixpanelValue: 'Self sign up shipping not available with get in touch',
  },
  hasTechnologies: {
    Component: HasTechnologies,
    mixpanelValue: 'Self sign up has technologies',
  },
  hasTechnologiesWithRoamingRestrictions: {
    Component: HasTechnologies,
    mixpanelValue: 'Self sign up has technologies with roaming restrictions',
  },
  customsFeeRequired: {
    Component: CustomsFeeRequired,
    mixpanelValue: 'Self sign up customs fee required',
  },
};

function Qualification({ nextStep, formData, unsafeMetadata }) {
  const locationAndTechnologies = useLocationAndTechnologies();

  const [locations, setLocations] = useState(LOADING_COUNTRIES);
  const [canContinue, setCanContinue] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [countryInformation, setCountryInformation] = useState(null);
  const [messagesToDisplay, setMessagesToDisplay] = useState([]);

  useEffect(() => {
    if (countryCode) {
      const location = locationAndTechnologies.data.find(countryData => countryData.countryCode === countryCode);
      const freeTrialTechnologies = location.technologies.filter(tech => tech.availableWithFreeTrial);
      const techExclusivelyInPoc = location.technologies.filter(
        tech => tech.availableWithPoc && !tech.availableWithFreeTrial,
      );
      setCountryInformation({ location, freeTrialTechnologies, techExclusivelyInPoc });
    }
  }, [countryCode, locationAndTechnologies.data]);

  const getMixpanelProperties = useCallback(
    messages => {
      if (!countryInformation) return {};

      const freeTrialTechnologiesArray = countryInformation.freeTrialTechnologies.map(t => t.tech);
      const techExclusivelyInPocArray = countryInformation.techExclusivelyInPoc.map(t => t.tech);
      const mixpanelMessageValues = messages.map(({ mixpanelValue }) => mixpanelValue);

      return {
        Email: formData.emailAddress,
        Firstname: formData.firstName,
        Lastname: formData.lastName,
        Company: unsafeMetadata.companyName,
        'Self sign up test country name': countryInformation.location.country,
        'Self sign up test free trial technologies available': freeTrialTechnologiesArray,
        'Self sign up test paid poc extended technologies available': techExclusivelyInPocArray,
        'Self sign up test information messages': mixpanelMessageValues,
      };
    },
    [countryInformation, formData.emailAddress, formData.firstName, formData.lastName, unsafeMetadata.companyName],
  );

  const sendMixpanelEvent = useCallback(
    ({ event, messages }) => {
      if (!countryInformation) return;

      const mixpanelProperties = getMixpanelProperties(messages);
      mixpanel.track(event, mixpanelProperties);
    },
    [countryInformation, getMixpanelProperties],
  );

  const getMainMessage = useCallback(() => {
    if (!countryInformation.location.shippingData.shippingPossible) {
      setCanContinue(false);
      return MESSAGE_MAP.shippingNotAvailableWithGetInTouch;
    }

    if (!countryInformation.location.shippingData.importPossible) {
      setCanContinue(false);
      return MESSAGE_MAP.shippingNotAvailable;
    }

    if (!countryInformation.freeTrialTechnologies.length) {
      setCanContinue(false);
      return MESSAGE_MAP.noTechnologies;
    }

    setCanContinue(true);

    if (countryInformation.freeTrialTechnologies.some(t => t.permanentRoamingRestriction)) {
      return MESSAGE_MAP.hasTechnologiesWithRoamingRestrictions;
    }

    return MESSAGE_MAP.hasTechnologies;
  }, [countryInformation]);

  const getMessagesToDisplay = useCallback(() => {
    if (!countryInformation) return [];
    const shippingIsAvailable =
      countryInformation.location.shippingData.shippingPossible &&
      countryInformation.location.shippingData.importPossible;

    const messages = [getMainMessage()];

    if (!shippingIsAvailable) return messages;

    if (countryInformation.techExclusivelyInPoc.length) messages.push(MESSAGE_MAP.pocPromotion);
    if (countryInformation.location.shippingData.customsFeeRequired) messages.push(MESSAGE_MAP.customsFeeRequired);

    return messages;
  }, [countryInformation, getMainMessage]);

  useEffect(() => {
    const messages = getMessagesToDisplay();

    setMessagesToDisplay(messages);
    sendMixpanelEvent({ event: 'Self sign up select country', messages });
  }, [countryInformation, getMessagesToDisplay, sendMixpanelEvent]);

  useEffect(() => {
    if (locationAndTechnologies.data) {
      const newLocations = locationAndTechnologies.data
        .map(({ countryCode, country }) => ({
          label: country,
          value: countryCode,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setLocations(newLocations);
    }
  }, [locationAndTechnologies.data]);

  useEffect(() => {
    if (locationAndTechnologies.error) nextStep();
  }, [locationAndTechnologies.error, nextStep]);

  const CountryInformation = useCallback(() => {
    return messagesToDisplay.map(({ Component }, index) => {
      return (
        <Component
          key={index}
          {...countryInformation}
        />
      );
    });
  }, [messagesToDisplay, countryInformation]);

  function FullNetworkCoverageLink() {
    return (
      <StyledLabelContainer>
        For our full network availability, check our{' '}
        <Link
          target='_blank'
          href={process.env.FULL_NETWORK_COVERAGE_LINK}
          onClick={() =>
            sendMixpanelEvent({ event: 'Self sign up click coverage map link', messages: messagesToDisplay })
          }
        >
          Coverage Map
        </Link>
      </StyledLabelContainer>
    );
  }

  function CanContinueButton() {
    return (
      <StyledContinueBtnWrapper>
        <ContinueBtn
          onClick={e => {
            e.preventDefault();
            nextStep({
              mixpanel: {
                event: 'Self sign up requested verification email',
                properties: getMixpanelProperties(messagesToDisplay),
              },
            });
          }}
        >
          Continue
        </ContinueBtn>
      </StyledContinueBtnWrapper>
    );
  }

  return (
    <>
      <SignUpCardTitle>Testing location</SignUpCardTitle>
      <SubTitleWrapper>
        <ModalParagraph>
          Please select your primary testing location to check our free testing availability.
        </ModalParagraph>
      </SubTitleWrapper>
      <StyledForm marginBottom={!canContinue && 24}>
        <CountryInput
          type='select'
          canSearch
          noMargin
          label='Primary country for testing'
          placeholder='Select...'
          items={locations}
          onChange={value => {
            setCountryCode(value);
          }}
          value={countryCode}
        />
        {countryInformation && <CountryInformation />}
        {countryCode && <FullNetworkCoverageLink />}
        {canContinue && <CanContinueButton />}
      </StyledForm>
    </>
  );
}

Qualification.propTypes = {
  nextStep: PropTypes.func,
  formData: PropTypes.object,
  unsafeMetadata: PropTypes.object,
};

export default Qualification;
