// --------------------
// Colors v2
// 2024 -> 2024
// --------------------
// https://www.figma.com/file/Mmqat1JtfdQjtv3Lu2Dx76
// --------------------

const version2 = {
  // --------------------
  // Temporary colors
  // --------------------
  // These colors are in the new design system, but are not yet properly implemented
  // https://www.figma.com/design/edp8Jm4u5954IDSQJ5utgn/ODS---Components?node-id=5429-92415&m=dev
  // --------------------
  temp: {
    info: {
      typography: {
        primary: '#5E6269',
        secondary: '#7C7D88',
      },
    },
    promotion: {
      background: '#FEF9F6',
      border: '#E5CFC3',
      icon: '#875A47',
      typography: '#875A47',
    },
    warning: {
      background: '#FEFBE0',
      border: '#EEE190',
      icon: '#775F28',
      typography: '#775F28',
    },
    error: {
      background: '#FFF8F7',
      border: '#F9C6C6',
    },
    button: {
      background: '#F2EBED',
    },
    gray: '#60646C',
  },

  primary: {
    gray: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EDEDED',
      300: '#D6D6D6',
      400: '#ABABAB',
      500: '#7A7A7A',
      600: '#5E5E5E',
      700: '#525252',
      800: '#474747',
      900: '#3D3D3D',
      950: '#191919',
    },
    copper: {
      50: '#F6F3F0',
      100: '#EAE0D7',
      200: '#D6C2B2',
      300: '#BE9C86',
      400: '#AB7D63',
      500: '#9C6C56',
      600: '#865748',
      700: '#6C433C',
      800: '#5C3B37',
      900: '#503433',
      950: '#2D1B1B',
    },
    green: {
      50: '#EFFAF2',
      100: '#D9F2DE',
      200: '#B6E4C3',
      300: '#86CF9F',
      400: '#53B477',
      500: '#329A5C',
      600: '#227947',
      700: '#1B613A',
      800: '#184D30',
      900: '#144028',
      950: '#0A2417',
    },
    orange: {
      50: '#FFFAEB',
      100: '#FFEFC6',
      200: '#FFDF88',
      300: '#FEC74B',
      400: '#FEB121',
      500: '#F88E08',
      600: '#DC6803',
      700: '#B64707',
      800: '#94360C',
      900: '#792D0E',
      950: '#461502',
    },
    red: {
      50: '#FEF2F2',
      100: '#FDE3E3',
      200: '#FDCBCB',
      300: '#FAA7A7',
      400: '#F56C6C',
      500: '#EC4747',
      600: '#D92929',
      700: '#B61F1F',
      800: '#971D1D',
      900: '#7D1F1F',
      950: '#440B0B',
    },
  },
};

// --------------------
// Colors V3
// 2024 -> 2024
// --------------------
// https://www.figma.com/design/edp8Jm4u5954IDSQJ5utgn/ODS---Components?node-id=5429-92415
// --------------------

const V3_RED = {
  main: {
    1: '#FFFCFC',
    2: '#FFF7F7',
    3: '#FFEFEF',
    4: '#FFE5E5',
    5: '#FDD8D8',
    6: '#F9C6C6',
    7: '#F3AEAF',
    8: '#EB9091',
    9: '#E5484D',
    10: '#D93D42',
    11: '#C62A2F',
    12: '#641723',
  },
  dark: {
    1: '#1F1315',
    2: '#291618',
    3: '#3B191D',
    4: '#481A20',
    5: '#551C22',
    6: '#691D25',
    7: '#8C1D28',
    8: '#D21E24',
    9: '#E5484D',
    10: '#F26669',
    11: '#FF8589',
    12: '#FFD1D9',
  },
};
const V3_RED_ALPHA = {
  mainAlpha: {
    1: 'color(display-p3 0.675 0.024 0.024 / 0.012)',
    2: 'color(display-p3 0.863 0.024 0.024 / 0.028)',
    3: 'color(display-p3 0.792 0.008 0.008 / 0.075)',
    4: 'color(display-p3 1 0.008 0.008 / 0.134)',
    5: 'color(display-p3 0.918 0.008 0.008 / 0.189)',
    6: 'color(display-p3 0.831 0.02 0.004 / 0.251)',
    7: 'color(display-p3 0.741 0.016 0.004 / 0.33)',
    8: 'color(display-p3 0.698 0.012 0.004 / 0.428)',
    9: 'color(display-p3 0.749 0.008 0 / 0.675)',
    10: 'color(display-p3 0.714 0.012 0 / 0.714)',
    11: 'color(display-p3 0.744 0.234 0.222)',
    12: 'color(display-p3 0.36 0.115 0.143)',
  },
  darkAlpha: {
    1: 'color(display-p3 0.984 0.071 0.071 / 0.03)',
    2: 'color(display-p3 0.996 0.282 0.282 / 0.055)',
    3: 'color(display-p3 1 0.169 0.271 / 0.156)',
    4: 'color(display-p3 1 0.118 0.267 / 0.236)',
    5: 'color(display-p3 1 0.212 0.314 / 0.303)',
    6: 'color(display-p3 1 0.318 0.38 / 0.374)',
    7: 'color(display-p3 1 0.4 0.424 / 0.475)',
    8: 'color(display-p3 1 0.431 0.431 / 0.635)',
    9: 'color(display-p3 1 0.388 0.384 / 0.82)',
    10: 'color(display-p3 1 0.463 0.447 / 0.853)',
    11: 'color(display-p3 1 0.57 0.55)',
    12: 'color(display-p3 0.971 0.826 0.852)',
  },
};
const V3_BLUE = {
  main: {
    1: '#FBFDFF',
    2: '#F5FAFF',
    3: '#EDF6FF',
    4: '#E1F0FF',
    5: '#CEE7FE',
    6: '#B7D9F8',
    7: '#96C7F2',
    8: '#5EB0EF',
    9: '#0091FF',
    10: '#0880EA',
    11: '#0B68CB',
    12: '#113264',
  },
  dark: {
    1: '#0F1720',
    2: '#0F1B2D',
    3: '#11253F',
    4: '#102A4C',
    5: '#12325A',
    6: '#123D6F',
    7: '#0F5096',
    8: '#1276E2',
    9: '#0091FF',
    10: '#3CABFF',
    11: '#6BC1FF',
    12: '#C2E5FF',
  },
};
const V3_BLUE_ALPHA = {
  mainAlpha: {
    1: 'color(display-p3 0.024 0.514 1 / 0.016)',
    2: 'color(display-p3 0.024 0.514 0.906 / 0.04)',
    3: 'color(display-p3 0.012 0.506 0.914 / 0.087)',
    4: 'color(display-p3 0.008 0.545 1 / 0.146)',
    5: 'color(display-p3 0.004 0.502 0.984 / 0.212)',
    6: 'color(display-p3 0.004 0.463 0.922 / 0.291)',
    7: 'color(display-p3 0.004 0.431 0.863 / 0.393)',
    8: 'color(display-p3 0 0.427 0.851 / 0.55)',
    9: 'color(display-p3 0 0.412 0.961 / 0.753)',
    10: 'color(display-p3 0 0.376 0.886 / 0.765)',
    11: 'color(display-p3 0.15 0.44 0.84)',
    12: 'color(display-p3 0.102 0.193 0.379)',
  },
  darkAlpha: {
    1: 'color(display-p3 0 0.333 1 / 0.059)',
    2: 'color(display-p3 0.114 0.435 0.988 / 0.085)',
    3: 'color(display-p3 0.122 0.463 1 / 0.219)',
    4: 'color(display-p3 0 0.467 1 / 0.324)',
    5: 'color(display-p3 0.098 0.51 1 / 0.4)',
    6: 'color(display-p3 0.224 0.557 1 / 0.475)',
    7: 'color(display-p3 0.294 0.584 1 / 0.572)',
    8: 'color(display-p3 0.314 0.592 1 / 0.702)',
    9: 'color(display-p3 0.251 0.573 0.996 / 0.967)',
    10: 'color(display-p3 0.357 0.631 1 / 0.971)',
    11: 'color(display-p3 0.49 0.72 1)',
    12: 'color(display-p3 0.788 0.898 0.99)',
  },
};
const V3_GREEN = {
  main: {
    1: '#FBFEFC',
    2: '#F2FCF5',
    3: '#E9F9EE',
    4: '#DDF3E4',
    5: '#CCEBD7',
    6: '#B4DFC4',
    7: '#92CEAC',
    8: '#5BB98C',
    9: '#30A46C',
    10: '#299764',
    11: '#18794E',
    12: '#193B2D',
  },
  dark: {
    1: '#0D1912',
    2: '#0F1D17',
    3: '#12281F',
    4: '#143125',
    5: '#173A2A',
    6: '#194633',
    7: '#1F5E41',
    8: '#2C8C5E',
    9: '#30A46C',
    10: '#35B979',
    11: '#3DD68C',
    12: '#B1F1CB',
  },
};
const V3_GREEN_ALPHA = {
  mainAlpha: {
    1: 'color(display-p3 0.024 0.757 0.267 / 0.016)',
    2: 'color(display-p3 0.024 0.565 0.129 / 0.036)',
    3: 'color(display-p3 0.012 0.596 0.145 / 0.087)',
    4: 'color(display-p3 0.008 0.588 0.145 / 0.142)',
    5: 'color(display-p3 0.004 0.541 0.157 / 0.204)',
    6: 'color(display-p3 0.004 0.518 0.157 / 0.283)',
    7: 'color(display-p3 0.004 0.486 0.165 / 0.389)',
    8: 'color(display-p3 0 0.478 0.2 / 0.55)',
    9: 'color(display-p3 0 0.455 0.165 / 0.667)',
    10: 'color(display-p3 0 0.416 0.153 / 0.691)',
    11: 'color(display-p3 0.19 0.5 0.32)',
    12: 'color(display-p3 0.132 0.228 0.18)',
  },
  darkAlpha: {
    1: 'color(display-p3 0 0.992 0.298 / 0.017)',
    2: 'color(display-p3 0.341 0.98 0.616 / 0.043)',
    3: 'color(display-p3 0.376 0.996 0.655 / 0.114)',
    4: 'color(display-p3 0.341 0.996 0.635 / 0.173)',
    5: 'color(display-p3 0.408 1 0.678 / 0.232)',
    6: 'color(display-p3 0.475 1 0.706 / 0.29)',
    7: 'color(display-p3 0.514 1 0.706 / 0.362)',
    8: 'color(display-p3 0.529 1 0.718 / 0.442)',
    9: 'color(display-p3 0.502 0.996 0.682 / 0.61)',
    10: 'color(display-p3 0.506 1 0.682 / 0.66)',
    11: 'color(display-p3 0.434 0.828 0.573)',
    12: 'color(display-p3 0.747 0.938 0.807)',
  },
};
const V3_YELLOW = {
  main: {
    1: '#FDFDF9',
    2: '#FFFBE0',
    3: '#FFF8C6',
    4: '#FCF3AF',
    5: '#F7EA9B',
    6: '#ECDD85',
    7: '#DAC56E',
    8: '#C9AA45',
    9: '#FBE32D',
    10: '#F9DA10',
    11: '#775F28',
    12: '#473B1F',
  },
  dark: {
    1: '#1C1500',
    2: '#221A04',
    3: '#2C230A',
    4: '#342A0E',
    5: '#3D3211',
    6: '#493D14',
    7: '#615119',
    8: '#8F7D24',
    9: '#FBE32D',
    10: '#FCEA5C',
    11: '#FFEE33',
    12: '#FFF5AD',
  },
};
const V3_YELLOW_ALPHA = {
  mainAlpha: {
    1: 'color(display-p3 0.675 0.675 0.024 / 0.024)',
    2: 'color(display-p3 0.953 0.855 0.008 / 0.079)',
    3: 'color(display-p3 0.988 0.925 0.004 / 0.251)',
    4: 'color(display-p3 0.98 0.875 0.004 / 0.373)',
    5: 'color(display-p3 0.969 0.816 0.004 / 0.491)',
    6: 'color(display-p3 0.875 0.71 0 / 0.526)',
    7: 'color(display-p3 0.769 0.604 0 / 0.542)',
    8: 'color(display-p3 0.725 0.549 0 / 0.687)',
    9: 'color(display-p3 1 0.898 0 / 0.781)',
    10: 'color(display-p3 0.969 0.812 0 / 0.71)',
    11: 'color(display-p3 0.6 0.44 0)',
    12: 'color(display-p3 0.271 0.233 0.137)',
  },
  darkAlpha: {
    1: 'color(display-p3 0.973 0.369 0 / 0.013)',
    2: 'color(display-p3 0.996 0.792 0 / 0.038)',
    3: 'color(display-p3 0.996 0.71 0 / 0.11)',
    4: 'color(display-p3 0.996 0.741 0 / 0.152)',
    5: 'color(display-p3 0.996 0.765 0 / 0.202)',
    6: 'color(display-p3 0.996 0.816 0.082 / 0.261)',
    7: 'color(display-p3 1 0.831 0.263 / 0.345)',
    8: 'color(display-p3 1 0.831 0.314 / 0.463)',
    9: 'color(display-p3 1 0.922 0.22)',
    10: 'color(display-p3 1 1 0.455)',
    11: 'color(display-p3 0.948 0.885 0.392)',
    12: 'color(display-p3 0.959 0.934 0.731)',
  },
};
const V3_GRAY = {
  main: {
    1: '#FCFCFC',
    2: '#F9F9F9',
    3: '#F1F1F1',
    4: '#EBEBEB',
    5: '#E4E4E4',
    6: '#DDDDDD',
    7: '#D4D4D4',
    8: '#BBBBBB',
    9: '#8D8D8D',
    10: '#808080',
    11: '#646464',
    12: '#202020',
  },
  dark: {
    1: '#181818',
    2: '#1B1B1B',
    3: '#282828',
    4: '#303030',
    5: '#373737',
    6: '#3F3F3F',
    7: '#4A4A4A',
    8: '#606060',
    9: '#6E6E6E',
    10: '#818181',
    11: '#B1B1B1',
    12: '#EEEEEE',
  },
};
const V3_GRAY_ALPHA = {
  mainAlpha: {
    1: 'color(display-p3 0 0 0 / 0.012)',
    2: 'color(display-p3 0 0 0 / 0.024)',
    3: 'color(display-p3 0 0 0 / 0.063)',
    4: 'color(display-p3 0 0 0 / 0.09)',
    5: 'color(display-p3 0 0 0 / 0.122)',
    6: 'color(display-p3 0 0 0 / 0.153)',
    7: 'color(display-p3 0 0 0 / 0.192)',
    8: 'color(display-p3 0 0 0 / 0.267)',
    9: 'color(display-p3 0 0 0 / 0.447)',
    10: 'color(display-p3 0 0 0 / 0.486)',
    11: 'color(display-p3 0 0 0 / 0.608)',
    12: 'color(display-p3 0 0 0 / 0.875)',
  },
  darkAlpha: {
    1: 'color(display-p3 0 0 0 / 0)',
    2: 'color(display-p3 1 1 1 / 0.034)',
    3: 'color(display-p3 1 1 1 / 0.071)',
    4: 'color(display-p3 1 1 1 / 0.105)',
    5: 'color(display-p3 1 1 1 / 0.134)',
    6: 'color(display-p3 1 1 1 / 0.172)',
    7: 'color(display-p3 1 1 1 / 0.231)',
    8: 'color(display-p3 1 1 1 / 0.332)',
    9: 'color(display-p3 1 1 1 / 0.391)',
    10: 'color(display-p3 1 1 1 / 0.445)',
    11: 'color(display-p3 1 1 1 / 0.685)',
    12: 'color(display-p3 1 1 1 / 0.929)',
  },
};
const V3_ORANGE = {
  main: {
    1: '#FEFCFB',
    2: '#FFF8F4',
    3: '#FFEDD5',
    4: '#FFE0BB',
    5: '#FFD3A4',
    6: '#FFC291',
    7: '#FFAA7D',
    8: '#ED8A5C',
    9: '#F76808',
    10: '#ED5F00',
    11: '#99543A',
    12: '#582D1D',
  },
  dark: {
    1: '#1F1206',
    2: '#271504',
    3: '#341C0A',
    4: '#3F220D',
    5: '#4B2910',
    6: '#5D3213',
    7: '#7E4318',
    8: '#C36522',
    9: '#F76808',
    10: '#FF802B',
    11: '#FFA366',
    12: '#FFE0C2',
  },
};
const V3_ACCENT = {
  main: {
    1: '#FEF9F6',
    2: '#FDFCFC',
    3: '#F9F0EC',
    4: '#F4E8E1',
    5: '#EDDDD4',
    6: '#E5CFC3',
    7: '#D6B8A8',
    8: '#C79F8A',
    9: '#AB7D63',
    10: '#A0725A',
    11: '#885B49',
    12: '#4A3026',
  },
  mainAlpha: {
    1: 'rgba(88, 5, 5, 0.01)',
    2: 'rgba(199, 73, 5, 0.04)',
    3: 'rgba(151, 56, 1, 0.07)',
    4: 'rgba(132, 46, 0, 0.11)',
    5: 'rgba(132, 51, 0, 0.16)',
    6: 'rgba(122, 43, 0, 0.22)',
    7: 'rgba(132, 44, 0, 0.33)',
    8: 'rgba(132, 47, 1, 0.46)',
    9: 'rgba(117, 43, 0, 0.61)',
    10: 'rgba(107, 38, 1, 0.65)',
    11: 'rgba(86, 25, 0, 0.71)',
    12: 'rgba(41, 11, 0, 0.85)',
  },
  dark: {
    1: '#1C1512',
    2: '#1E1815',
    3: '#2B221D',
    4: '#352922',
    5: '#3E2F28',
    6: '#4C392F',
    7: '#664C3D',
    8: '#9A725B',
    9: '#AB7D63',
    10: '#BD947A',
    11: '#DBB49F',
    12: '#F0E1D6',
  },
  darkAlpha: {
    1: 'rgba(224, 0, 0, 0)',
    2: 'rgba(246, 80, 24, 0.02)',
    3: 'rgba(255, 178, 147, 0.06)',
    4: 'rgba(255, 188, 159, 0.1)',
    5: 'rgba(254, 190, 168, 0.14)',
    6: 'rgba(254, 194, 169, 0.2)',
    7: 'rgba(255, 197, 175, 0.3)',
    8: 'rgba(255, 201, 178, 0.51)',
    9: 'rgba(255, 199, 175, 0.59)',
    10: 'rgba(255, 206, 184, 0.67)',
    11: 'rgba(255, 215, 197, 0.81)',
    12: 'rgba(255, 241, 233, 0.92)',
  },
};
const V3_TOMATO = {
  main: {
    1: '#FFFCFC',
    2: '#FFF8F7',
    3: '#FEEBE7',
    4: '#FFDCD3',
    5: '#FFCDC2',
    6: '#FDBDAF',
    7: '#F5A898',
    8: '#EC8E7B',
    9: '#E54D2E',
    10: '#DD4425',
    11: '#D13415',
    12: '#5C271F',
  },
  dark: {
    1: '#5C271F',
    2: '#1F1513',
    3: '#391714',
    4: '#4E1511',
    5: '#5E1C16',
    6: '#6E2920',
    7: '#853A2D',
    8: '#AC4D39',
    9: '#E54D2E',
    10: '#EC6142',
    11: '#FF977D',
    12: '#FBD3CB',
  },
};

const version3 = {
  accent: V3_ACCENT,
  tomato: V3_TOMATO,
  red: V3_RED,
  blue: V3_BLUE,
  green: V3_GREEN,
  yellow: V3_YELLOW,
  gray: V3_GRAY,
  neutral: { ...V3_GRAY, ...V3_GRAY_ALPHA },
  success: { ...V3_GREEN, ...V3_GREEN_ALPHA },
  error: { ...V3_RED, ...V3_RED_ALPHA },
  warning: { ...V3_YELLOW, ...V3_YELLOW_ALPHA },
  info: { ...V3_BLUE, ...V3_BLUE_ALPHA },
  orange: V3_ORANGE,
};

export default {
  version2,
  version3,
};
