import React from 'react';
import { Form, Title } from '~/components/LoginElements';

function UserIsLocked() {
  return (
    <Form>
      <Title>Your account has been locked</Title>
      Your account has had too many failed authentication attempts, please contact support.
    </Form>
  );
}

export default UserIsLocked;
