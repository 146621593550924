import React, { useRef } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import useTrackPageView from '~/hooks/use-track-page-view';
import useAuthentication from '~/hooks/use-authentication';
import useNotification from '~/hooks/use-notification';
import Input from '~/components/Input';
import {
  Wrapper,
  HeaderImg,
  Form,
  FormBottomWrapper,
  SubmitButton,
  Title,
  Divider,
  SsoWrapper,
} from '~/components/LoginElements';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

function Login() {
  useTrackPageView('Log In');
  const authentication = useAuthentication();
  const { addNotification } = useNotification();
  const { state: locationState } = useLocation();
  const email = useRef();
  const password = useRef();

  async function onSubmit(e) {
    e.preventDefault();
    try {
      await authentication.login({
        email: email.current.value,
        password: password.current.value,
      });
    } catch (e) {
      const isResponseError = !!e.response;
      addNotification({
        message: `Error: ${isResponseError ? 'Invalid username or password' : e.message}`,
        type: 'error',
      });
    }
  }

  if (authentication.isLoggedIn) {
    return <Redirect to={locationState?.from || '/'} />;
  }

  return (
    <Wrapper>
      <HeaderImg
        width={145}
        height={20}
        src={require('~/assets/graphics/onomondo-logo-type-negative.svg')}
      />
      <Form onSubmit={onSubmit}>
        <Title>Log in</Title>
        <Input
          ref={email}
          name='email'
          type='email'
          label='Email'
          autoFocus
        />
        <Input
          ref={password}
          name='password'
          type='password'
          label='Password'
        />

        <FormBottomWrapper>
          <Link to='/forgot-password'>Forgot password</Link>
          <SubmitButton
            variant='primary'
            type='submit'
          >
            Log in
          </SubmitButton>
        </FormBottomWrapper>
        <Divider>Or</Divider>
        <SsoWrapper>
          <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
            <GoogleLogin
              size='medium'
              width='400px'
              onSuccess={credentialResponse => {
                authentication.loginWithGoogle(credentialResponse);
              }}
            />
          </GoogleOAuthProvider>
        </SsoWrapper>
      </Form>
    </Wrapper>
  );
}

export default Login;
