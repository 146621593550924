import { createGlobalStyle } from 'styled-components';
import 'modali/dist/modali.css';
import theme from '~/lib/theme';

// https://gist.github.com/thisisadityarao/cc86efbdd916c6031f8ca3601c107d71
const GlobalStyles = createGlobalStyle`
  /****** IMPORTED FONTS ******/
  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Light.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Light.woff')}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Italic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Italic.woff')}) format('woff');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Medium.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Medium.woff')}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Regular.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Regular.woff')}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-MediumItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-MediumItalic.woff')}) format('woff');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-LightItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-LightItalic.woff')}) format('woff');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-Bold.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-Bold.woff')}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Neue Montreal';
    src: url(${require('~/assets/fonts/NeueMontreal-BoldItalic.woff2')}) format('woff2'),
         url(${require('~/assets/fonts/NeueMontreal-BoldItalic.woff')}) format('woff');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${require('~/assets/fonts/Roboto-Regular.ttf')});
    font-weight: 400;
    font-style: normal;
  }

  /* ==========================================================================
    GLOBAL STYLES
    ========================================================================== */

  /* Box Sizing
    ========================================================================== */
  html {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    font-size: 14px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body,
  button,
  input,
  select,
  textarea {
    font-family: 'NeueMontreal', sans-serif;
    font-weight: 300;
  }

  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    /* background-color: ${props => props.theme.bgColor.muted}; */
    background-color: ${props => props.theme.gray};
  }

  #root {
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  /* PARAGRAPHS
    ========================================================================== */

  p {
    font-family: 'Neue Montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${props => props.theme.darker};
    margin: 0;
    hyphens: auto;
  }

  /* HEADINGS
    ========================================================================== */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Neue Montreal';
    color: ${props => props.theme.darker};
    margin: 0;
  }

  h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
  }

  h2 {
    color: ${props => props.theme.dark};
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
  }

  h3 {
    color: ${props => props.theme.copperDarkest};
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${props => props.theme.darker};
  }

  /* TABLES
    ========================================================================== */

  th {
    font-family: 'Neue Montreal';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: ${props => props.theme.dark};
    vertical-align: bottom;
  }

  td {
    font-family: 'Neue Montreal';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${props => props.theme.darker};
    margin: 0;
    hyphens: auto;
  }

  /* LINKS
    ========================================================================== */

  a {
    text-decoration: none;
    color: ${props => props.theme.link.color};
  }
  a[data-underline] {
    text-decoration: underline;
  }

  /* A better looking default horizontal rule
    ========================================================================== */

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  /*
  * Remove the gap between audio, canvas, iframes,
  * images, videos and the bottom of their containers:
  * https://github.com/h5bp/html5-boilerplate/issues/440
  ========================================================================== */

  img,
  svg {
    vertical-align: middle;
  }

  /* IMAGES
    ========================================================================== */

  img {
    height: auto;
    width: auto;
    /* max-width allows image to be responsive. */
    max-width: 100%;
  }

  /* LISTS
    ========================================================================== */
  ul {
    padding-left: 20px;
  }

  /* dont add padding from modali when it's open */
  @media (min-width: 1025px) {
    .modali-open {
      padding-right: 0 !important;
      overflow: unset !important;
    }
  }

  /* make modal body from modali scrollable */
  .modali-body-style {
    max-height: 70vh;
    overflow-y: auto;
  }

  .modali-overlay {
    z-index: ${theme.zIndex.modal};
  }
  .modali-wrapper {
    z-index: ${theme.zIndex.modal + 1};
  }

  /* stop modali from using both in animation-fill-mode */
  .modali-animated {
    animation-fill-mode: none !important;
  }
`;

export default GlobalStyles;
