import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Title, Form } from '~/components/LoginElements';
import Icon from '~/components/Icon';
import theme from '~/lib/theme';

const StyledArrowRightIcon = styled.div`
  transition: transform 0.1s ease-in-out;
`;

const CardButton = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  transition: background-color 0.1s ease-in-out;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
    `}

  &:hover {
    background-color: ${props => props.theme.bgColor.muted};
    cursor: pointer;

    & ${StyledArrowRightIcon} {
      transform: translateX(-5px);
    }
  }
`;

function ChooseOrganization({ organizations, onClick }) {
  const [loading, setLoading] = useState(false);

  return (
    <Form as='div'>
      <Title>Choose organization</Title>
      {organizations.map(t => (
        <CardButton
          key={t.id}
          disabled={loading}
          onClick={() => {
            setLoading(true);
            onClick(t.id);
          }}
        >
          {t.name}
          <Suspense fallback={<p>loading...</p>}>
            <StyledArrowRightIcon>
              <Icon
                name='arrow-right'
                width={18}
                height={18}
                color={theme.darkMedium}
              />
            </StyledArrowRightIcon>
          </Suspense>
        </CardButton>
      ))}
    </Form>
  );
}

ChooseOrganization.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
};

export default ChooseOrganization;
