import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '~/components/PrivateRoute';
import useHubspot from '~/hooks/use-hubspot';
import useHotjar from '~/hooks/use-hotjar';
import NotFound from '~/pages/NotFound';
import NetworkServicePage from '~/pages/Admin/NetworkService';
import useEvent from '~/hooks/use-events';
import CountriesServicePage from '~/pages/Admin/CountriesService';

const Organization = lazy(() => import(/* webpackChunkName: "organization" */ '~/pages/Admin/Organization'));
const Organizations = lazy(() => import(/* webpackChunkName: "organizations" */ '~/pages/Admin/Organizations'));
const Pricelists = lazy(() => import(/* webpackChunkName: "pricelists" */ '~/pages/Admin/Pricelists'));
const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ '~/pages/Dashboard'));
const Sims = lazy(() => import(/* webpackChunkName: "sims" */ '~/pages/Sims'));
const TrafficAnalyzer = lazy(() => import(/* webpackChunkName: "trafficAnalyzer" */ '~/pages/TrafficAnalyzer'));
const LiveMap = lazy(() => import(/* webpackChunkName: "liveMap" */ '~/pages/LiveMap'));
const Usage = lazy(() => import(/* webpackChunkName: "usage" */ '~/pages/Usage'));
const Invoices = lazy(() => import(/* webpackChunkName: "invoices" */ '~/pages/Invoices'));
const AuditLogs = lazy(() => import(/* webpackChunkName: "usage" */ '~/pages/AuditLogs'));
const Connectors = lazy(() => import(/* webpackChunkName: "connectors" */ '~/pages/Connectors'));
const NetworkLists = lazy(() => import(/* webpackChunkName: "networklists" */ '~/pages/NetworkLists'));
const Tags = lazy(() => import(/* webpackChunkName: "tags" */ '~/pages/Tags'));
const Users = lazy(() => import(/* webpackChunkName: "users" */ '~/pages/Users'));
const Webhooks = lazy(() => import(/* webpackChunkName: "webhooks" */ '~/pages/Webhooks'));
const ErrorLog = lazy(() => import(/* webpackChunkName: "errorLog" */ '~/pages/ErrorLog'));
const ApiKeys = lazy(() => import(/* webpackChunkName: "apiKeys" */ '~/pages/ApiKeys'));
const Settings = lazy(() => import(/* webpackChunkName: "settings" */ '~/pages/Settings'));

function PrivateRoutes() {
  useHubspot();
  useHotjar();
  useEvent();

  return (
    <Switch>
      <PrivateRoute
        path='/network-whitelist'
        exact
        render={() => <Redirect to={{ pathname: '/network-lists' }} />}
      />

      <PrivateRoute
        path='/'
        exact
        render={() => <Redirect to={{ pathname: '/dashboard' }} />}
      />

      <PrivateRoute
        path='/dashboard'
        exact
      >
        <Dashboard />
      </PrivateRoute>

      <PrivateRoute path='/traffic-analyzer'>
        <TrafficAnalyzer />
      </PrivateRoute>

      <PrivateRoute path='/live-map'>
        <LiveMap />
      </PrivateRoute>

      <PrivateRoute path='/sims'>
        <Sims />
      </PrivateRoute>

      <PrivateRoute
        path='/usage'
        exact
      >
        <Usage />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['owner', 'demigod', 'god']}
        path='/invoices'
      >
        <Invoices />
      </PrivateRoute>

      <PrivateRoute path='/connectors'>
        <Connectors />
      </PrivateRoute>

      <PrivateRoute path='/network-lists'>
        <NetworkLists />
      </PrivateRoute>

      <PrivateRoute path='/api-keys'>
        <ApiKeys />
      </PrivateRoute>

      <PrivateRoute path='/settings'>
        <Settings />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['admin', 'owner', 'god']}
        path='/tags'
      >
        <Tags />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['admin', 'owner', 'god']}
        path='/users'
      >
        <Users />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['admin', 'owner', 'god']}
        path='/audit-logs'
      >
        <AuditLogs />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['admin', 'owner', 'god']}
        path='/webhooks'
      >
        <Webhooks />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['admin', 'owner', 'god']}
        path='/error-log'
      >
        <ErrorLog />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['demigod', 'god']}
        path='/admin'
        exact
      >
        <Redirect to={{ pathname: '/admin/organizations' }} />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['god']}
        path='/admin/pricelists'
        exact
      >
        <Pricelists />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['demigod', 'god']}
        path='/admin/organizations'
        exact
      >
        <Organizations />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['god']}
        path='/admin/network-service'
        exact
      >
        <NetworkServicePage />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['god']}
        path='/admin/countries-service'
        exact
      >
        <CountriesServicePage />
      </PrivateRoute>

      <PrivateRoute
        requiredRoles={['god']}
        path='/admin/organizations/:organizationId'
      >
        <Organization />
      </PrivateRoute>

      <PrivateRoute path='*'>
        <NotFound />
      </PrivateRoute>
    </Switch>
  );
}

export default PrivateRoutes;
